import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import urlJoin from 'url-join';
import { getOffset } from '@@client/lib/pagination';
import { paramUrl } from '@@client/lib/url';
import { bearer, responseData, cacheStandardRequest } from '@@client/lib/request';
export var listDistinct = cacheStandardRequest(600, function (tokenCache, apiUrl, _ref) {
  var page = _ref.page,
      perPage = _ref.perPage,
      order = _ref.order,
      queryRest = _objectWithoutProperties(_ref, ["page", "perPage", "order"]);

  // 600 seconds == 10 minutes
  var query = _objectSpread({
    order: order,
    num: perPage,
    offset: getOffset(page, perPage)
  }, queryRest);

  var url = paramUrl(urlJoin(apiUrl, '/api/v1/make/distinct'), query);
  return tokenCache.get().then(function (token) {
    return fetch(url, {
      method: 'get',
      headers: {
        'Authorization': bearer(token)
      }
    });
  }).then(responseData).then(function (_ref2) {
    var response = _ref2.response,
        data = _ref2.data;

    if (response.ok) {
      return {
        success: true,
        entities: data.entities,
        numTotal: data.numTotal
      };
    } else {
      return {
        success: false,
        errors: data.errors
      };
    }
  });
});