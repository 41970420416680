var dollarRegex = /^[1-9]\d*(((,\d{3}){1})?(\.\d{0,2})?)$/;

var isDollars = function isDollars(x) {
  return dollarRegex.test(x);
};

export default function makeOfferValidate(amount) {
  var _amount;

  amount = (_amount = amount) !== null && _amount !== void 0 && _amount.trim ? amount.trim() : amount;

  if (!amount || !isDollars(amount)) {
    return {
      isValid: false,
      errors: ['Please enter a valid dollar amount, greater than $1.']
    };
  } else {
    return {
      isValid: true,
      errors: []
    };
  }
}