import * as api from './api';
import actionTypes, { l } from './action-types';
var text = {
  singular: 'part type name',
  plural: 'part type names'
};

function getListRequest() {
  return {
    type: actionTypes["GET_".concat(l, "_LIST_REQUEST")]
  };
}

function getListSuccess(entities, numTotal) {
  return {
    type: actionTypes["GET_".concat(l, "_LIST_SUCCESS")],
    entities: entities,
    numTotal: numTotal
  };
}

function getListFailure(errors) {
  return {
    type: actionTypes["GET_".concat(l, "_LIST_FAILURE")],
    errors: errors
  };
}

export function getPartTypeNameList(tokenCache) {
  return function (dispatch, getState) {
    var state = getState();
    dispatch(getListRequest());
    api.list(tokenCache, state.config.inventory.apiUrl, {
      sort: 'name',
      order: 'asc',
      page: 1,
      perPage: -1
    }).then(function (data) {
      if (data.errors) dispatch(getListFailure(data.errors));else dispatch(getListSuccess(data.entities, data.numTotal));
    })["catch"](function (_) {
      return dispatch(getListFailure(["Something went wrong and ".concat(text.plural, " could not be fetched. Please try again.")]));
    });
  };
}