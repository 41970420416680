import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTokenCache } from '@@client/lib/hooks';
import { getApplicationList, clearApplicationList } from './actions';
export default function loadApplicationList(year, carlineName, partTypeSlug) {
  var dispatch = useDispatch();
  var tokenCache = useTokenCache();
  useEffect(function () {
    if (!year || !carlineName || !partTypeSlug) {
      dispatch(clearApplicationList());
    } else {
      dispatch(getApplicationList(tokenCache, {
        year: year,
        carlineName: carlineName,
        partTypeSlug: partTypeSlug
      }));
    }
  }, [year, carlineName, partTypeSlug]);
  return null;
}