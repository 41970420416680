import {useState} from 'react'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {sendEvent} from '@@client/lib/gtm'
import {hasRetailPrice} from '@@client/parts/parts-helper'
import {addCartItem} from '@@client/cart/actions'
import {useInCart, useCart} from '@@client/cart/cart-hooks'
import MakeOfferModal from '@@client/cart/MakeOfferModal'
import ItemInCartModal from '@@client/cart/ItemInCartModal'

const mapStateToProps = () => ({})

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators({
        addCartItem,
    }, dispatch),
})

const PartCartActions = ({
    actions,
    partInfo,
    allowMakeOffer = true,
}) => {
    const [isMakeOfferOpen, setIsMakeOfferOpen] = useState(false)
    const [isInCartOpen, setIsInCartOpen] = useState(false)
    const inCart = useInCart(partInfo.inventoryId)
    const cart = useCart()
    const allowAddToCartButton = hasRetailPrice(partInfo.retailPrice)

    const handleAddToCart = () => {
        sendEvent('add-to-cart')

        actions.addCartItem(partInfo)
        setIsInCartOpen(true)
    }

    const renderMakeOfferModal = () => {
        if(!allowMakeOffer) return null

        const handleSuccess = () => {
            setIsMakeOfferOpen(false)
            setIsInCartOpen(true)
        }

        const offeredAmount = cart.offers[partInfo.inventoryId] || ''

        return (
            <MakeOfferModal
                partInfo={partInfo}
                isOpen={isMakeOfferOpen}
                amount={offeredAmount}
                onClose={() => setIsMakeOfferOpen(false)}
                onSuccess={handleSuccess}
            />
        )
    }

    const renderButtons = () => {
        if(inCart) {
            return <button className='btn btn--slim btn--disabled'>Item in Cart</button>
        }
        else {
            return (
                <>
                    {allowMakeOffer ? (<button className='btn btn--slim btn--blue--light make-offer' onClick={() => setIsMakeOfferOpen(true)}>Make Offer</button>) : null}
                    {allowAddToCartButton ? <button className='btn btn--slim btn--blue--dark add-to-cart' onClick={handleAddToCart}>Add to Cart</button> : null}
                </>
            )
        }
    }

    return (
        <>
            {renderMakeOfferModal()}
            <ItemInCartModal
                partInfo={partInfo}
                isOpen={isInCartOpen}
                onClose={() => setIsInCartOpen(false)}
            />
            <div className='actions'>
                {renderButtons()}
            </div>
            <style jsx>
                {`
                    @import '@css/variables.css';

                    .actions {
                        @media(--sm) {
                            display: flex;
                        }

                        :global(.btn) {
                            display: block;
                            text-align: center;
                            width: 100%;
                            margin: 0 0 8px 0;

                            &:last-child { margin-bottom: 0; }

                            @media(--sm) {
                                font-size: 13px;
                                padding: 8px 5px;
                                line-height: 1.1;
                                margin-bottom: 0;
                                margin-right: 5px;
                                &:last-child { margin-right: 0; }
                            }
                        }
                    }
                `}
            </style>
        </>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(PartCartActions)
