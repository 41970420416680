import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTokenCache } from '@@client/lib/hooks';
import { getPartTypeNameList } from './actions';
export default function loadPartTypeNameList() {
  var partTypeName = useSelector(function (state) {
    return state.parts.partTypeName;
  });
  var dispatch = useDispatch();
  var tokenCache = useTokenCache();
  useEffect(function () {
    // already doing, no need to fetch again
    if (partTypeName.list.doing) return;
    dispatch(getPartTypeNameList(tokenCache));
  }, []);
  return null;
}