import urlJoin from 'url-join';
import { get } from '@cullylarson/f';
import { getOffset } from '@@client/lib/pagination';
import { paramUrl } from '@@client/lib/url';
import { bearer, responseData, cacheStandardRequest } from '@@client/lib/request';
var noImageFoundUrl = '/images/part-types/no-image-available.png';
var fallbackUrlsByPartType = {
  '114': '/images/part-types/114-headlamp-assembly.png',
  '117': '/images/part-types/117-hood.png',
  '120': '/images/part-types/120-front-door-assembly.png',
  '125': '/images/part-types/125-front-door-window-regulator-manual.png',
  '128': '/images/part-types/128-mirror.png',
  '129': '/images/part-types/129-exterior-door-handle.png',
  '130': '/images/part-types/130-rear-door-assembly.png',
  '140': '/images/part-types/140-door-assembly-rear-or-back.png',
  '166': '/images/part-types/166-tail-lamp.png',
  '202': '/images/part-types/202-front-seat.png',
  '204': '/images/part-types/204-front-door-interior-trim-panel.png',
  '205': '/images/part-types/205-rear-door-interior-trim-panel.png',
  '210': '/images/part-types/210-front-seat-belt.png',
  '229': '/images/part-types/229-interior-door-handle.png',
  '253': '/images/part-types/253-air-bag.png',
  '257': '/images/part-types/257-speedometer-cluster.png',
  '267': '/images/part-types/267-interior-mirror.png',
  '268': '/images/part-types/268-sun-visor.png',
  '277': '/images/part-types/277-front-door-glass.png',
  '278': '/images/part-types/278-rear-door-glass.png',
  '300': '/images/part-types/300-engine-assembly.png',
  '311': '/images/part-types/311-oil-pan.png',
  '321': '/images/part-types/321-turbo.png',
  '327': '/images/part-types/327-exhaust-manifold.png',
  '336': '/images/part-types/336-air-flow-meter.png',
  '400': '/images/part-types/400-transmission-assembly.png',
  '412': '/images/part-types/412-transfer-case-assembmly.png',
  '435': '/images/part-types/435-rear-axle-assembly.png',
  '490': '/images/part-types/490-rear-stub-axle.png',
  '511': '/images/part-types/511-rear-upper-control-arm.png',
  '512': '/images/part-types/512-front-lower-control-arm.png',
  '513': '/images/part-types/513-rear-lower-control-arm.png',
  '515': '/images/part-types/515-font-spindle.png',
  '526': '/images/part-types/526-shock-absorber.png',
  '530': '/images/part-types/530-front-brakes.png',
  '533': '/images/part-types/533-rear-brakes.png',
  '540': '/images/part-types/540-power-brake-booster.png',
  '541': '/images/part-types/541-brake-master-cylinder.png',
  '551': '/images/part-types/551-steering-gear.png',
  '553': '/images/part-types/553-power-steering-pump.png',
  '560': '/images/part-types/560-wheel.png',
  '585': '/images/part-types/585-tires.png',
  '590': '/images/part-types/590-brain-box.png',
  '591': '/images/part-types/591-transmission-module.png',
  '601': '/images/part-types/601-alternator.png',
  '604': '/images/part-types/604-starter-motor.png',
  '606': '/images/part-types/606-ignition-distributor.png',
  '615': '/images/part-types/615-heater-blower-motor.png',
  '617': '/images/part-types/617-power-window-motor.png',
  '620': '/images/part-types/620-wiper-motor.png',
  '627': '/images/part-types/627-windshield-washer-reservoir.png',
  '638': '/images/part-types/638-a-v-equipment.png',
  '641': '/images/part-types/641-electrical-switch-panel.png',
  '655': '/images/part-types/655-temperature-control.png',
  '671': '/images/part-types/671-coolant-reservoir.png',
  '679': '/images/part-types/679-ac-condenser.png',
  '682': '/images/part-types/682-ac-compressor.png'
};
export var MEDIA_SIZES = {
  FULL: null,
  HERO: 'hero',
  // 2800px
  BANNER: 'banner',
  // 1200px
  LARGE: 'large',
  // 1024px
  MEDIUM: 'medium',
  // 768px
  SMALL: 'small',
  // 500px
  PREVIEW: 'preview',
  // 300px
  THUMBNAIL: 'thumbnail',
  // 200px
  LQIP: 'lqip' // 10px (any size you want)

};
export var getFeaturedUrl = function getFeaturedUrl(apiUrl, sizeSlug, inventoryId, araVehicleId) {
  return paramUrl(urlJoin(apiUrl, '/api/v1/inventory/featured', sizeSlug || ''), {
    iid: inventoryId || undefined,
    vid: araVehicleId || undefined
  });
};
export var getImageSizeUrl = function getImageSizeUrl(apiUrl, sizeSlug, imageId) {
  return paramUrl(urlJoin(apiUrl, '/api/v1/inventory/photo', imageId, sizeSlug));
};
export var getFallbackUrlByPartType = function getFallbackUrlByPartType(partType) {
  if (!partType) return noImageFoundUrl;
  return get(partType.toString(), noImageFoundUrl, fallbackUrlsByPartType);
};
export var listInventoryPhotos = cacheStandardRequest(600, function (tokenCache, apiUrl, inventoryId, _ref) {
  var sizeSlug = _ref.sizeSlug,
      page = _ref.page,
      perPage = _ref.perPage;
  // 600 seconds == 10 minutes
  var query = {
    inventoryId: inventoryId,
    sizeSlug: sizeSlug,
    num: perPage,
    offset: getOffset(page, perPage)
  };
  var url = paramUrl(urlJoin(apiUrl, '/api/v1/inventory/part'), query);
  return tokenCache.get().then(function (token) {
    return fetch(url, {
      method: 'get',
      headers: {
        'Authorization': bearer(token)
      }
    });
  }).then(responseData).then(function (_ref2) {
    var response = _ref2.response,
        data = _ref2.data;

    if (response.ok) {
      return {
        success: true,
        entities: data.entities,
        numTotal: data.numTotal
      };
    } else {
      return {
        success: false,
        errors: data.errors
      };
    }
  });
});
export var listVehiclePhotos = cacheStandardRequest(600, function (tokenCache, apiUrl, araVehicleId, _ref3) {
  var sizeSlug = _ref3.sizeSlug,
      page = _ref3.page,
      perPage = _ref3.perPage;
  // 600 seconds == 10 minutes
  var query = {
    araVehicleId: araVehicleId,
    sizeSlug: sizeSlug,
    num: perPage,
    offset: getOffset(page, perPage)
  };
  var url = paramUrl(urlJoin(apiUrl, '/api/v1/inventory/vehicle'), query);
  return tokenCache.get().then(function (token) {
    return fetch(url, {
      method: 'get',
      headers: {
        'Authorization': bearer(token)
      }
    });
  }).then(responseData).then(function (_ref4) {
    var response = _ref4.response,
        data = _ref4.data;

    if (response.ok) {
      return {
        success: true,
        entities: data.entities,
        numTotal: data.numTotal
      };
    } else {
      return {
        success: false,
        errors: data.errors
      };
    }
  });
});