import {useState, useEffect} from 'react'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {idFromPrefix} from '@@client/lib/forms'
import {preventDefault} from '@@client/lib/events'
import {sendEvent} from '@@client/lib/gtm'
import {formatNumber, formatDollars} from '@@client/lib/numbers'
import {labelStyle, valueStyle} from '@@client/parts/styles'
import Modal from '@@client/components/Modal'
import FormPrice from '@@client/components/FormPrice'
import PartPhotoFeatured from '@@client/parts/PartPhotoFeatured'
import {useDefaultWarrantyInfo} from '@@client/cart/cart-hooks'
import {useConfig} from '@@client/lib/hooks'
import {makeOfferItem, addCartItem} from './actions'
import makeOfferValidate from './make-offer-validate'

const MakeOfferForm = ({
    partInfo,
    selectedWarrantyInfo,
    idPrefix,
    amount,
    amountErrors,
    onAmountChange,
    onSubmit,
    disabled = false,
}) => {
    const config = useConfig()
    const hasCore = Boolean(partInfo.corePrice)

    const renderWarrantyOption = () => {
        if(config.inventory.noWarrantyPartTypes.includes(partInfo.partType)) {
            return (<p><em>Warranty Option: This part does not come with a warranty.</em></p>)
        }
        else if(!selectedWarrantyInfo) {
            return null
        }
        else {
            return (
                <>
                    <div className='warranty'>
                        <div className={labelStyle.className}>Warranty Option</div>
                        <div className={valueStyle.className}>{selectedWarrantyInfo.titleShort}</div>
                    </div>
                    <style jsx>
                        {`
                            .warranty {
                                margin-bottom: 15px;
                            }
                        `}
                    </style>
                </>
            )
        }
    }

    return (
        <form onSubmit={onSubmit}>
            <div className='prices'>
                <FormPrice
                    idPrefix={idPrefix}
                    name='amount'
                    title='Your offer price'
                    titleClassName={labelStyle.className}
                    value={amount}
                    onChange={onAmountChange}
                    errors={amountErrors}
                />

                {hasCore ? <div className='core-price'>+ {formatDollars(partInfo.corePrice)} Core Deposit</div> : null}
            </div>

            {renderWarrantyOption()}

            <div className='actions'>
                <button className='btn btn--blue--dark' type='submit' disabled={disabled}>Add to Cart</button>
            </div>
            {labelStyle.styles}
            {valueStyle.styles}
            <style jsx>
                {`
                    @import '@css/variables.css';

                    .prices {
                        margin-bottom: 15px;
                    }

                    .prices :global(.field) {
                        margin-bottom: 0;
                    }

                    .actions {
                        text-align: center;
                    }

                    .core-price {
                        font-style: italic;
                        font-size: $size--small;
                        margin-top: 5px;
                    }
                `}
            </style>
        </form>
    )
}

const mapStateToProps = ({config}) => ({config})

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators({
        makeOfferItem,
        addCartItem,
    }, dispatch),
})

const MakeOfferModal = ({
    config,
    actions,
    partInfo,
    amount = '',
    isOpen,
    onSuccess = () => {},
    onClose = () => {},
}) => {
    const [amountControlled, setAmountControlled] = useState(amount)
    const [amountErrors, setAmountErrors] = useState({})
    const selectedWarrantyInfo = useDefaultWarrantyInfo()

    useEffect(() => {
        // clear errors
        setAmountErrors([])
    }, [isOpen])

    const handleSubmit = preventDefault(() => {
        const validationResult = makeOfferValidate(amountControlled)

        if(!validationResult.isValid) {
            setAmountErrors(validationResult.errors)
        }
        else {
            sendEvent('add-to-cart')

            setAmountErrors([])
            actions.addCartItem(partInfo)
            actions.makeOfferItem(partInfo.inventoryId, amountControlled)
            onSuccess()
        }
    })

    const handleAmountChange = e => (e && e.target) ? setAmountControlled(e.target.value) : setAmountControlled(e)
    const idPrefix = 'make-offer-modal-' + partInfo.inventoryId

    return (
        <>
            <div className='container'>
                <Modal
                    className='no-body-styles'
                    initialFocus={'#' + idFromPrefix(idPrefix, 'amount')}
                    isOpen={isOpen}
                    onClose={onClose}
                    title='Make an Offer'
                    subtitle='The offer you submit will be evaluated by a sales representative and either accepted or denied. If accepted, you will be charged the offered amount. If denied, we will contact you with a counter offer. Extended warranty pricing options are non-negotiable.'
                >
                    <div className='details'>
                        <div className='photo'><PartPhotoFeatured partInfo={partInfo} /></div>
                        <div className='part'>
                            <div className='part-type'>
                                <div className={labelStyle.className}>Part</div>
                                <div className={valueStyle.className}>{partInfo.partTypeName}</div>
                            </div>
                            <div className='vehicle'>
                                <div className={labelStyle.className}>Vehicle Information</div>
                                <div className={valueStyle.className}>{partInfo.modelYear} {partInfo.makeName} {partInfo.carlineName}</div>
                            </div>
                            <div className='mileage-stock'>
                                <div className='mileage'>
                                    <div className={labelStyle.className}>Mileage</div>
                                    <div className={valueStyle.className}>{partInfo.mileage ? formatNumber(partInfo.mileage) : <span>&mdash;</span>}</div>
                                </div>
                                <div className='part-rating t-xs'>
                                    <div className={labelStyle.className}>Part Rating</div>
                                    <div className={valueStyle.className}>{partInfo.partRating || <span>&mdash;</span>}</div>
                                </div>
                                <div className='stock-number'>
                                    <div className={labelStyle.className}>Stock #</div>
                                    <div className={valueStyle.className}>{partInfo.stockTicketNumber}</div>
                                </div>
                            </div>
                            <div className='location t-xs'>
                                <div className={labelStyle.className}>Location</div>
                                <div className={valueStyle.className}>{partInfo.storeName || <span>&mdash;</span>}</div>
                            </div>
                        </div>
                        <div className='the-form'>
                            <MakeOfferForm
                                partInfo={partInfo}
                                selectedWarrantyInfo={selectedWarrantyInfo}
                                idPrefix={idPrefix}
                                onSubmit={handleSubmit}
                                amountErrors={amountErrors}
                                amount={amountControlled}
                                onAmountChange={handleAmountChange}
                            />
                        </div>
                    </div>
                </Modal>
            </div>
            {labelStyle.styles}
            {valueStyle.styles}
            <style jsx>
                {`
                    @import '@css/variables.css';

                    $itemMarginBottom: 15px;

                    .container :global(.subtitle) {
                        text-align: left;
                    }

                    .details {
                        display: grid;
                        grid-template-columns: 1fr 230px;
                        width: 100%;

                        @media(--xxs) { grid-template-columns: 1fr 1fr; }
                        @media(--xxxs) { display: block; }

                        @media(--sm) { grid-template-columns: 170px 1fr 170px; }
                        @media(--sm-mid) { grid-template-columns: 190px 1fr 190px; }
                        @media(--md) { grid-template-columns: 226px 1fr 230px; }
                    }

                    .photo {
                        order: 2;

                        @media(--sm) { order: unset; }
                    }

                    .part {
                        background: $c--gray--bg;
                        padding: 20px 14px;

                        order: 1;
                        grid-row: auto / span 2;

                        @media(--sm) {
                            order: unset;
                            grid-row: auto;
                        }
                    }

                    .container :global(.value) {
                        font-weight: $weight--bold;
                        line-height: $line-height--short;
                    }

                    .the-form {
                        padding: 20px 14px;
                        background: $c--blue--lightest;
                        order: 3;

                        @media(--sm) { order: unset; }

                        :global(.actions button) {
                            @media(--sm-max) {
                                padding-left: 10px;
                                padding-right: 10px;
                                width: 100%;
                            }
                        }
                    }

                    .part-rating,
                    .part-type,
                    .vehicle,
                    .container :global(.warranty) {
                        margin-bottom: $itemMarginBottom;
                    }

                    .vehicle {
                        @media(--xs) {
                            padding-bottom: $itemMarginBottom;
                            border-bottom: 1px solid $c--blue--light-gray;
                        }
                    }

                    .mileage,
                    .part-rating,
                    .stock-number,
                    .location {
                        display: grid;
                        grid-template-columns: 70px 1fr;
                        grid-gap: 10px;

                        @media(--xxs) { display: block; }
                        @media(--xxxs) { display: grid; }
                        @media(--sm) { display: block; }
                    }

                    .mileage-stock {
                        margin-bottom: $itemMarginBottom;

                        @media(--sm) {
                            display: flex;
                            margin-bottom: 0;
                        }
                    }

                    .mileage {
                        margin-bottom: $itemMarginBottom;

                        @media(--sm) {
                            margin-bottom: 0;
                            margin-right: 35px;
                        }

                        @media(--md) {
                            margin-right: 55px;
                        }
                    }
                `}
            </style>
        </>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(MakeOfferModal)
