import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTokenCache } from '@@client/lib/hooks';
import { getMakeListDistinct } from './actions';
export default function loadMakeListDistinct() {
  var page = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
  var perPage = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : -1;
  var make = useSelector(function (state) {
    return state.parts.make;
  });
  var dispatch = useDispatch();
  var tokenCache = useTokenCache();
  useEffect(function () {
    // already doing, no need to fetch again
    if (make.listDistinct.doing) return;
    dispatch(getMakeListDistinct(tokenCache, page, perPage));
  }, [page, perPage]);
  return null;
}