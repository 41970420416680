import Link from 'next/link'
import Image from 'next/image'
import Modal from '@@client/components/Modal'
import {classnames} from '@@client/lib/classes'
import {formatNumber, formatDollars} from '@@client/lib/numbers'
import {useIsMakeOffer, useSelectedWarrantyInfo, useItemPrice} from '@@client/cart/cart-hooks'
import {useConfig} from '@@client/lib/hooks'
import {getWarrantyCost} from '@@client/cart/cart-repo'
import PartPhotoFeatured from '@@client/parts/PartPhotoFeatured'

export default function ItemInCartModal({
    partInfo,
    isOpen,
    onClose = () => {},
}) {
    const config = useConfig()
    const isMakeOffer = useIsMakeOffer(partInfo.inventoryId)
    const selectedWarrantyInfo = useSelectedWarrantyInfo(partInfo.inventoryId)
    const itemPrice = useItemPrice(partInfo.inventoryId)
    const warrantyCost = getWarrantyCost(selectedWarrantyInfo, itemPrice)
    const hasCore = Boolean(partInfo.corePrice)

    const renderWarrantyOption = () => {
        if(config.inventory.noWarrantyPartTypes.includes(partInfo.partType)) {
            return (<p><em>Warranty Option: This part does not come with a warranty.</em></p>)
        }
        else if(!selectedWarrantyInfo) {
            return null
        }
        else {
            return (
                <>
                    <div className='warranty'>
                        <div className='label'>Warranty Option:</div>
                        <div className='value'>{formatDollars(warrantyCost)} - {selectedWarrantyInfo.titleShort}*</div>
                        <div className='adendum'>* Warranty options can be changed during checkout.</div>
                    </div>
                    <style jsx>
                        {`
                            .adendum {
                                margin-top: 5px;
                                font-size: 12px;
                                opacity: 0.5;
                                line-height: 1.2;
                            }
                        `}
                    </style>
                </>
            )
        }
    }

    return (
        <>
            <div className='container'>
                <Modal
                    className='no-body-styles item-in-cart-modal'
                    isOpen={isOpen}
                    onClose={onClose}
                    title='Item Added to Cart'
                    titleIcon={<Image width='32' height='32' src='/images/icons/icon-checkcircle.svg' />}
                >
                    <div className='details'>
                        <div className='photo'>
                            <PartPhotoFeatured partInfo={partInfo} />
                        </div>
                        <div className='part'>
                            <div className='part-type'>
                                <div className='label'>Part:</div>
                                <div className='value'>{partInfo.partTypeName}</div>
                            </div>
                            <div className='vehicle'>
                                <div className='label'>Vehicle Information:</div>
                                <div className='value'>{partInfo.modelYear} {partInfo.makeName} {partInfo.carlineName}</div>
                            </div>
                            <div className='mileage-stock'>
                                <div className='mileage'>
                                    <div className='label'>Mileage:</div>
                                    <div className='value'>{partInfo.mileage ? formatNumber(partInfo.mileage) : <span>&mdash;</span>}</div>
                                </div>
                                <div className='stock-number'>
                                    <div className='label'>Stock #:</div>
                                    <div className='value'>{partInfo.stockTicketNumber}</div>
                                </div>
                            </div>
                            <div className='part-rating t-xs'>
                                <div className='label'>Part Rating:</div>
                                <div className='value'>{partInfo.partRating || <span>&mdash;</span>}</div>
                            </div>
                            <div className='location t-xs'>
                                <div className='label'>Location:</div>
                                <div className='value'>{partInfo.storeName || <span>&mdash;</span>}</div>
                            </div>
                        </div>
                        <div className='price-details'>
                            <div className='price'>
                                <div className='label'>Price</div>
                                <div className={classnames('price-value', 'value')}>{itemPrice ? (<span className='amount'>{formatDollars(itemPrice)}</span>) : (<span className='make-offer-price'>Make an offer</span>)}</div>
                                {isMakeOffer ? <div className='make-offer-price'>* Make-offer price</div> : null}
                                {hasCore ? <div className='core-price'>+ {formatDollars(partInfo.corePrice)} Core Deposit</div> : null}
                            </div>
                            {renderWarrantyOption()}
                        </div>
                    </div>
                    <div className='actions'>
                        <button className='btn btn--cancel continue-shopping' onClick={onClose}>Continue Shopping</button>
                        <Link href='/cart'><a className='btn btn--yellow checkout'>Checkout Now</a></Link>
                    </div>
                </Modal>
            </div>
            <style jsx>
                {`
                    @import '@css/variables.css';

                    $itemMarginBottom: 15px;

                    .details {
                        display: grid;
                        grid-template-columns: 1fr 0.9fr;
                        width: 100%;

                        @media(--xxxs) { display: block; }

                        @media(--sm) { grid-template-columns: 170px 1fr 170px; }
                        @media(--sm-mid) { grid-template-columns: 190px 1fr 180px; }
                        @media(--md) { grid-template-columns: 226px 1fr 0.7fr; }
                    }

                    .photo {
                        order: 2;

                        @media(--sm) { order: unset; }
                    }

                    .part {
                        background: $c--gray--bg;
                        padding: 20px 14px;

                        order: 1;
                        grid-row: auto / span 2;

                        @media(--sm) {
                            order: unset;
                            grid-row: auto;
                        }
                    }

                    .details :global(.label) {
                        font-size: $size--smaller;
                        font-style: italic;
                        margin-bottom: 1px;
                        display: block;
                    }

                    .details :global(.value) {
                        font-weight: $weight--bold;
                        line-height: $line-height--short;
                    }

                    .vehicle {
                        @media(--xs) {
                            padding-bottom: $itemMarginBottom;
                            border-bottom: 1px solid $c--blue--light-gray;
                        }
                    }

                    .part-type,
                    .vehicle,
                    .warranty {
                        margin-bottom: $itemMarginBottom;
                    }

                    .mileage-stock {
                        margin-bottom: $itemMarginBottom;

                        @media(--sm) {
                            display: flex;
                            margin-bottom: 0;
                        }
                    }

                    .mileage,
                    .stock-number,
                    .part-rating,
                    .location {
                        @media(--xs) {
                            display: grid;
                            grid-template-columns: 70px 1fr;
                            grid-gap: 10px;
                        }
                    }

                    .part-rating {
                        margin-bottom: $itemMarginBottom;
                    }

                    .mileage {
                        margin-right: 55px;

                        @media(--xs) { margin: 0 0 $itemMarginBottom 0; }
                    }

                    .price-details {
                        background: $c--blue--lightest;
                        padding: 20px 14px;
                        order: 3;

                        @media(--sm) { order: unset; }
                    }

                    .price {
                        margin-bottom: $itemMarginBottom;

                        .core-price,
                        .make-offer-price {
                            font-style: italic;
                            font-size: $size--small;
                        }

                        .price-value {
                            font-size: $size--larger;
                        }
                    }

                    .actions {
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        grid-gap: 10px;
                        padding: 20px 14px;
                        text-align: center;

                        @media(--xxxs) { display: block; }
                        @media(--sm) { display: block; }

                        .continue-shopping {
                            @media(--sm) {
                                margin-right: 10px;
                            }
                        }

                        :global(button, a.btn) {
                            @media(--xs) {
                                width: 100%;
                                padding-left: 10px;
                                padding-right: 10px;
                            }

                            @media(--xxxs) {
                                margin-bottom: 10px;
                            }
                        }
                    }
                `}
            </style>
        </>
    )
}
