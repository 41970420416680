import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import * as api from './api';
import actionTypes, { l } from './action-types';
var text = {
  singular: 'part',
  plural: 'parts'
};

function getNewArrivalsListRequest() {
  return {
    type: actionTypes["GET_".concat(l, "_NEW_ARRIVALS_LIST_REQUEST")]
  };
}

function getNewArrivalsListSuccess(entities, numTotal) {
  return {
    type: actionTypes["GET_".concat(l, "_NEW_ARRIVALS_LIST_SUCCESS")],
    entities: entities,
    numTotal: numTotal
  };
}

function getNewArrivalsListFailure(errors) {
  return {
    type: actionTypes["GET_".concat(l, "_NEW_ARRIVALS_LIST_FAILURE")],
    errors: errors
  };
}

export function getNewArrivalsList(tokenCache, _ref) {
  var partTypeSlug = _ref.partTypeSlug;
  return function (dispatch, getState) {
    var state = getState();
    dispatch(getNewArrivalsListRequest());
    api.getNewArrivalsList(tokenCache, state.config.inventory.apiUrl, {
      sort: 'random',
      page: 1,
      perPage: 6,
      goBackDays: 30,
      partTypeSlug: partTypeSlug
    }).then(function (data) {
      if (data.errors) dispatch(getNewArrivalsListFailure(data.errors));else dispatch(getNewArrivalsListSuccess(data.entities, data.numTotal));
    })["catch"](function (_) {
      return dispatch(getNewArrivalsListFailure(["Something went wrong and ".concat(text.plural, " could not be fetched. Please try again.")]));
    });
  };
}

function getOneRequest() {
  return {
    type: actionTypes["GET_ONE_".concat(l, "_REQUEST")]
  };
}

function getOneSuccess(entity) {
  return {
    type: actionTypes["GET_ONE_".concat(l, "_SUCCESS")],
    entity: entity
  };
}

function getOneFailure(errors) {
  return {
    type: actionTypes["GET_ONE_".concat(l, "_FAILURE")],
    errors: errors
  };
}

export function getOnePart(tokenCache, id) {
  return function (dispatch, getState) {
    var state = getState();
    dispatch(getOneRequest());
    return api.get(tokenCache, state.config.inventory.apiUrl, {
      id: id
    }).then(function (result) {
      if (result.success) {
        dispatch(getOneSuccess(result.entity));
      } else {
        dispatch(getOneFailure(result.errors));
      }

      return result;
    })["catch"](function (_) {
      var errors = ["Something went wrong and the ".concat(text.singular, " could not be fetched. Please try again.")];
      dispatch(getOneFailure(errors));
      return {
        status: 500,
        errors: errors
      };
    });
  };
}

function searchRequest() {
  return {
    type: actionTypes["SEARCH_".concat(l, "_REQUEST")]
  };
}

function searchSuccess(entities, numTotal, page) {
  return {
    type: actionTypes["SEARCH_".concat(l, "_SUCCESS")],
    entities: entities,
    numTotal: numTotal,
    page: page
  };
}

function searchFailure(errors) {
  return {
    type: actionTypes["SEARCH_".concat(l, "_FAILURE")],
    errors: errors
  };
}

export function searchParts(tokenCache, _ref2) {
  var _ref2$page = _ref2.page,
      page = _ref2$page === void 0 ? 1 : _ref2$page,
      _ref2$sort = _ref2.sort,
      sort = _ref2$sort === void 0 ? 'inventoryId' : _ref2$sort,
      _ref2$order = _ref2.order,
      order = _ref2$order === void 0 ? 'desc' : _ref2$order,
      _ref2$perPage = _ref2.perPage,
      perPage = _ref2$perPage === void 0 ? undefined : _ref2$perPage,
      _ref2$filter = _ref2.filter,
      filter = _ref2$filter === void 0 ? {} : _ref2$filter,
      searchParams = _objectWithoutProperties(_ref2, ["page", "sort", "order", "perPage", "filter"]);

  return function (dispatch, getState) {
    var state = getState();
    perPage = perPage === undefined ? state.parts.inventory.searchList.perPage : perPage;
    dispatch(searchRequest());
    api.search(tokenCache, state.config.inventory.apiUrl, _objectSpread(_objectSpread({}, searchParams), {}, {
      page: page,
      perPage: perPage,
      sort: sort,
      order: order,
      filter: filter
    })).then(function (data) {
      if (data.errors) dispatch(searchFailure(data.errors));else dispatch(searchSuccess(data.entities, data.numTotal, page));
    })["catch"](function (_) {
      return dispatch(searchFailure(["Something went wrong and ".concat(text.plural, " could not be fetched. Please try again.")]));
    });
  };
}