import urlJoin from 'url-join';
import { paramUrl } from '@@client/lib/url';
import { bearer, responseData, cacheStandardRequest } from '@@client/lib/request';
export var list = cacheStandardRequest(600, function (tokenCache, apiUrl, _ref) {
  var year = _ref.year,
      carlineName = _ref.carlineName,
      partTypeSlug = _ref.partTypeSlug;
  // 600 seconds == 10 minutes
  var query = {
    year: year,
    carlineName: carlineName,
    partTypeSlug: partTypeSlug
  };
  var url = paramUrl(urlJoin(apiUrl, '/api/v1/interchange/applications'), query);
  return tokenCache.get().then(function (token) {
    return fetch(url, {
      method: 'get',
      headers: {
        'Authorization': bearer(token)
      }
    });
  }).then(responseData).then(function (_ref2) {
    var response = _ref2.response,
        data = _ref2.data;

    if (response.ok) {
      return {
        success: true,
        entities: data.entities,
        numTotal: data.numTotal
      };
    } else {
      return {
        success: false,
        errors: data.errors
      };
    }
  });
});