import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTokenCache } from '@@client/lib/hooks';
import { getCarlineList } from './actions';
export default function loadCarlineList(make) {
  var dispatch = useDispatch();
  var tokenCache = useTokenCache();
  useEffect(function () {
    dispatch(getCarlineList(tokenCache, make));
  }, [make]);
  return null;
}