import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import * as api from './api';
import actionTypes, { l } from './action-types';
var text = {
  singular: 'make',
  plural: 'makes'
};

function getListDistinctRequest() {
  return {
    type: actionTypes["GET_".concat(l, "_LIST_DISTINCT_REQUEST")]
  };
}

function getListDistinctSuccess(entities, numTotal) {
  return {
    type: actionTypes["GET_".concat(l, "_LIST_DISTINCT_SUCCESS")],
    entities: entities,
    numTotal: numTotal
  };
}

function getListDistinctFailure(errors) {
  return {
    type: actionTypes["GET_".concat(l, "_LIST_DISTINCT_FAILURE")],
    errors: errors
  };
}

function getListDistinctFeaturedRequest() {
  return {
    type: actionTypes["GET_".concat(l, "_LIST_DISTINCT_FEATURED_REQUEST")]
  };
}

function getListDistinctFeaturedSuccess(entities, numTotal) {
  return {
    type: actionTypes["GET_".concat(l, "_LIST_DISTINCT_FEATURED_SUCCESS")],
    entities: entities,
    numTotal: numTotal
  };
}

function getListDistinctFeaturedFailure(errors) {
  return {
    type: actionTypes["GET_".concat(l, "_LIST_DISTINCT_FEATURED_FAILURE")],
    errors: errors
  };
}

function buildGetMakeListDistinct(onRequestFn, onSuccessFn, onFailureFn) {
  var queryPredefined = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
  return function (tokenCache) {
    var page = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
    var perPage = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : -1;
    return function (dispatch, getState) {
      var state = getState();
      dispatch(onRequestFn());
      api.listDistinct(tokenCache, state.config.inventory.apiUrl, _objectSpread({
        sort: 'name',
        order: 'asc',
        page: page,
        perPage: perPage
      }, queryPredefined)).then(function (data) {
        if (data.errors) dispatch(onFailureFn(data.errors));else dispatch(onSuccessFn(data.entities, data.numTotal));
      })["catch"](function (_) {
        return dispatch(onFailureFn(["Something went wrong and ".concat(text.plural, " could not be fetched. Please try again.")]));
      });
    };
  };
}

export var getMakeListDistinct = buildGetMakeListDistinct(getListDistinctRequest, getListDistinctSuccess, getListDistinctFailure);
export var getMakeListDistinctFeatured = buildGetMakeListDistinct(getListDistinctFeaturedRequest, getListDistinctFeaturedSuccess, getListDistinctFeaturedFailure, {
  isFeatured: true
});