import * as api from './api';
import actionTypes, { l } from './action-types';
var text = {
  singular: 'option',
  plural: 'options'
};

var clearApplicationListGeneric = function clearApplicationListGeneric(actionLabel) {
  return function () {
    return {
      type: actionTypes[actionLabel]
    };
  };
};

var getListRequestGeneric = function getListRequestGeneric(actionLabel) {
  return function () {
    return {
      type: actionTypes[actionLabel]
    };
  };
};

var getListSuccessGeneric = function getListSuccessGeneric(actionLabel) {
  return function (entities, numTotal) {
    return {
      type: actionTypes[actionLabel],
      entities: entities,
      numTotal: numTotal
    };
  };
};

var getListFailureGeneric = function getListFailureGeneric(actionLabel) {
  return function (errors) {
    return {
      type: actionTypes[actionLabel],
      errors: errors
    };
  };
};

var getApplicationListGeneric = function getApplicationListGeneric(requestAction, successAction, failureAction) {
  return function (tokenCache, _ref) {
    var year = _ref.year,
        carlineName = _ref.carlineName,
        partTypeSlug = _ref.partTypeSlug;
    return function (dispatch, getState) {
      var state = getState();
      dispatch(requestAction());
      api.list(tokenCache, state.config.inventory.apiUrl, {
        year: year,
        carlineName: carlineName,
        partTypeSlug: partTypeSlug
      }).then(function (data) {
        if (data.errors) dispatch(failureAction(data.errors));else dispatch(successAction(data.entities, data.numTotal));
      })["catch"](function (_) {
        return dispatch(failureAction(["Something went wrong and ".concat(text.plural, " could not be fetched. Please try again.")]));
      });
    };
  };
};

export var clearApplicationList = clearApplicationListGeneric("CLEAR_".concat(l, "_LIST"));
var getListRequest = getListRequestGeneric("GET_".concat(l, "_LIST_REQUEST"));
var getListSuccess = getListSuccessGeneric("GET_".concat(l, "_LIST_SUCCESS"));
var getListFailure = getListFailureGeneric("GET_".concat(l, "_LIST_FAILURE"));
export var getApplicationList = getApplicationListGeneric(getListRequest, getListSuccess, getListFailure);
export var clearApplicationListSidebar = clearApplicationListGeneric("CLEAR_".concat(l, "_LIST_SIDEBAR"));
var getListSidebarRequest = getListRequestGeneric("GET_".concat(l, "_LIST_SIDEBAR_REQUEST"));
var getListSidebarSuccess = getListSuccessGeneric("GET_".concat(l, "_LIST_SIDEBAR_SUCCESS"));
var getListSidebarFailure = getListFailureGeneric("GET_".concat(l, "_LIST_SIDEBAR_FAILURE"));
export var getApplicationListSidebar = getApplicationListGeneric(getListSidebarRequest, getListSidebarSuccess, getListSidebarFailure);