import urlJoin from 'url-join';
import { getOffset } from '@@client/lib/pagination';
import { paramUrl } from '@@client/lib/url';
import { bearer, responseData, cacheStandardRequest } from '@@client/lib/request';
export var list = cacheStandardRequest(600, function (tokenCache, apiUrl, _ref) {
  var page = _ref.page,
      perPage = _ref.perPage,
      order = _ref.order,
      make = _ref.make;
  // 600 seconds == 10 minutes
  var query = {
    order: order,
    num: perPage,
    offset: getOffset(page, perPage),
    make: make
  };
  var url = paramUrl(urlJoin(apiUrl, '/api/v1/carline'), query);
  return tokenCache.get().then(function (token) {
    return fetch(url, {
      method: 'get',
      headers: {
        'Authorization': bearer(token)
      }
    });
  }).then(responseData).then(function (_ref2) {
    var response = _ref2.response,
        data = _ref2.data;

    if (response.ok) {
      return {
        success: true,
        entities: data.entities,
        numTotal: data.numTotal
      };
    } else {
      return {
        success: false,
        errors: data.errors
      };
    }
  });
});